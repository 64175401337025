$font-family-headlines: MuseoSans700, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headlines;

  > em {
    font-family: MuseoSans700Italic, Museo Sans, -apple-system-body,
    -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
  }
}

p.site-description {
  font-family: MuseoSans500, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 15px;
  display: inline-block;
  font-size: 2.43rem;
}

.btn, button, .page-link {
  font-family: MuseoSans300, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;

  > em {
    font-family: MuseoSans500Italic, Museo Sans, -apple-system-body,
    -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
  }
}

/* NAV */
.nav-item {
  font-family: MuseoSans500, Museo Sans, -apple-system-body,
  -apple-system, BlinkMacSystemFont, Segoe UI, Liberation Sans, sans-serif;
  font-size: 1rem;
}