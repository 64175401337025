﻿/*
Theme Name: Newsroom
Author: Mike Morkes
Version: 3.0
*/

a:hover {
  color: var(--bs-warning);
}

a:active {
  color: var(--bs-warning);
}

/* Focus Customization */
a:focus, .post-image-thumbnail:focus-within, .carousel-inner:focus-within {
  border-color: var(--bs-info);
  border-style: dashed;
  outline: 0;
}

.social-media .social-badge:focus {
  border-radius: 50%;
}

.social-media .lg-social-badge:focus {
  border: none;
}

.social-media .lg-social-badge:focus > i, .social-media .lg-social-badge:focus > span {
  border-color: var(--bs-info);
  border-style: dashed;
  border-radius: 50%;
}

a.focused-text:focus, .post-copy a:focus {
  padding: 5px;
  border-radius: var(--bs-border-radius);
}

.post-image-thumbnail:focus-within, .carousel-inner:focus-within {
  border-radius: var(--bs-border-radius-xl);
}

.page-header {
  background-color: #e4e6f4;
  box-shadow: inset 0 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.4);
}

/* CARDS */
.card {
  border-radius: var(--bs-border-radius-xl);
}

/* NAV */
.navbar-brand:focus {
  border: none;
}

.navbar-brand img {
  border: 3px solid transparent;
  margin-left: 0;
  margin-top: 14px;
  border-radius: var(--bs-border-radius);;
}

.navbar-brand:focus img {
  border-color: var(--bs-info);
  border-style: dashed;
  border-width: 3px;
  outline: 0;
  border-radius: var(--bs-border-radius);;
}

.site-title a,
.site-description {
  color: var(--bs-primary);
}

.nav-link {
  /* transition: all 0.15s ease-in-out; */
  padding-bottom: 0;
  margin-bottom: 8px;
}

.nav-underline {
  gap: 2rem !important;
}

.nav-underline .nav-link {
  padding: 6px 6px 8px 6px;
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  border-bottom-color: var(--bs-danger);
  border-width: 3px;
  margin-bottom: 0;
}

.nav-underline .nav-link:hover {
  color: var(--bs-primary);
  border-bottom-color: var(--bs-danger);
  padding-bottom: 3px 5px 8px 5px;
  border-width: 3px;
  margin-bottom: 0;
  outline: 0;
}

.nav-underline .nav-link:focus {
  color: var(--bs-primary);
  border-bottom-color: var(--bs-danger);
  padding: 3px 5px 8px 5px;
  border-width: 3px;
  margin-bottom: 0;
  border-top-color: var(--bs-info);
  border-top-style: dashed;
  border-left-color: var(--bs-info);
  border-left-style: dashed;
  border-right-color: var(--bs-info);
  border-right-style: dashed;
  outline: 0;
}

.nav-underline .nav-link:focus-visible {
  box-shadow: none;
  outline: 0;
}

.dropdown-menu,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  margin-top: 24px !important;
  background-color: #003087;
  border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl);
  box-shadow: inset 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu .dropdown-item:hover {
  box-shadow: inset 0px 6px 5px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu .dropdown-item:hover a {
  color: var(--bs-primary);
}

.dropdown-menu .dropdown-item a {
  text-decoration: none;
}

.dropdown-menu > li > a {
  padding: 18px 20px;
  color: #fff;
}

/* CAROUSEL */
.carousel .carousel-indicators {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15px;
}

.carousel {
  background-color: var(--bs-info-rgb);
}

.carousel-inner {
  border: 3px solid transparent;
}

.carousel-control-prev,
.carousel-control-next {
  border: 3px solid transparent;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover, .carousel-control-next:focus {
  background-color: rgba(0, 0, 0, 0.4);
}

.carousel .carousel-indicators [data-bs-target] {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 6px;
  margin-right: 5px;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
}

.carousel .carousel-indicators [data-bs-target]:focus-visible {
  outline: 0;
}

.carousel .carousel-indicators [data-bs-target]:hover {
  background-color: var(--bs-info);
  color: #fff;
}

.carousel .carousel-indicators [data-bs-target]:focus {
  background-color: var(--bs-primary);
  color: #fff;
}

.carousel .carousel-indicators .text[data-bs-target] {
  text-indent: 0;
  font-size: 14px;
  line-height: 1;
}

.carousel .carousel-indicators .text.active[data-bs-target] {
  background-color: var(--bs-warning);
  color: #fff;
}

.carousel .carousel-indicators .text.active[data-bs-target]:focus {
  background-color: var(--bs-info);
  color: #fff;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 16%;
  left: 16%;
  bottom: 1.75rem;
  padding-bottom: 30px !important;
  text-align: left;
}

#carousel-play-btn {
  position: absolute;
  left: 20px;
  top: 30px;
  z-index: 500;
}

#carousel-play-btn:focus-visible {
  box-shadow: 0 0 0 4px rgba(0,0,0,1) !important;
}

.site-branding {
  padding-left: 0;
}

/* Post Images */

.post-image-thumbnail {
  overflow: hidden;
  width: 155px;
  height: 155px;
}

.post-image-thumbnail-img {
  width: auto;
  max-width: none !important;
  min-height: 155px !important;
  max-height: 155px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.post-image-thumbnail.enable-hover:hover {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
}

.post-image-frame {
  overflow: hidden;
  max-height: 350px;
}

.post-image-frame-img {
  display: block;
  min-width: 100%;
  max-width: 100%;
  height: auto;

}

@media screen and (max-width: 1199px) {
  .post-image-thumbnail {
    height: 122px;
    width: auto;
    max-width: 122px;
  }

  .post-image-thumbnail-img {
    max-height: 122px;
  }
}

/*==== Social Icons ====*/
#social {
  top: 0;
  float: none;
}

.social-profile {
  margin: 3px;

}


/* Social Media Icons */

.fa-stack {
  height: 2.2em !important;
  width: 2.2em !important;
}

.instagram-icon {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  color: rgba(0, 0, 0, 0);
  -webkit-background-clip: text;
}

span.insta-hover-inner-shadow:hover > i.instagram-shadow {
  text-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
}

span.insta-hover-outer-shadow:hover > i.instagram-shadow {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .instagram-icon {
    background: none;
    color: #f3b03c;
  }
}

.youtube-icon {
  color: #ff0f17;
}

.facebook-icon {
  color: #4267b2;
}

.linkedin-icon {
  color: #0073b1;
}

.pinterest-icon {
  color: #e60c23;
}

.twitter-icon {
  color: #000000;
}

ul.social-media li.col {
  float: left;
}

ul.social-media li.col-6 {
  width: 20%;
}

.social-media {
  font-size: 30px;
}

.social-media {
  display: inline-block;
  padding-top: 0 !important;
  padding-left: 0;
}

.social-media li {
  list-style: none;
  padding: 0 10px;
}

.single-hover-outer-shadow:hover {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

span.hover-outer-shadow:hover > i.fa-stack-2x::before {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

span.hover-inner-shadow:hover > i.fa-stack-1x::before {
  background-color: rgba(0, 0, 0, 0.5);
  color: transparent;
  text-shadow: 0px 0px 1px rgba(var(--bs-light-rgb), 1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.fa-stack.small {
  font-size: 0.5em;
}
i.fa-regular, i.fa-solid, i.fab {
  vertical-align: middle;
  margin-top: -2px;
}

.copyright {
  color: #565a60;
}

.site-info a {
  color: #565a60;
}

/* Tables */
.pointer {
  cursor: pointer !important;
}

.sortable:hover, .pointer:hover {
  background-color: var(--bs-light);
}

.sortable:active, .pointer:active {
  background-color: rgba(var(--bs-primary-rgb), .2);
}

/* To Top btn */

#top-link-block {
  opacity: 0;
  display: block;
  position: fixed;
  bottom: 150px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}
#top-link-block.pulse {
  opacity: 1;
  bottom: 15px;
  transition: all 0.4s linear;
}

ul.widget-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }